import React from 'react';

import axios from 'axios';

import Article, {Author, TwitterCommunity} from './GeneralClasses'

//const base_url = `http://localhost:61650/api/{api_version}`;
const base_url = `https://apidev.modeworx.com/api/{api_version}`; 
//const base_url = `https://apitest.modeworx.com/api/{api_version}`;
//const base_url = `https://api.modeworx.com/api/{api_version}`;
const api_version = `1.0`;
//const base_url_get_article = `/Article/GetArticle?article_id={article_id}`;
const base_url_get_article = `/Article/GetArticle`;
const base_url_get_latest_article = `/Article/GetLatestArticle`;
const base_url_get_articles = `/Article/GetArticles?page={page}&results_per_page={results_per_page}`;
const base_url_get_articles_total_pages = `/Article/GetTotalPagesArticles`;
const base_url_get_twitter_communities = `/TwitterCommunity/GetTwitterCommunities`;
const base_url_submit_twitter_community = `/TwitterCommunity/SubmitTwitterCommunityForReview`;
const base_url_get_twitter_communities_total_pages = `/TwitterCommunity/GetTotalPagesTwitterCommunities`;

const app_key = "f9ababfe-48a2-40c6-9b4b-d34dacdf353d";

export default class APIManager extends React.Component { 

    state = {
      articles: [],
      current_article: null
    }
  
    constructor(props) {
        super(props);
        this.GetArticles = this.GetArticles.bind(this);
    };

    async GetArticles(filter, page, page_qty, sort)
    {

        if(page == undefined) { page = 1; }
        if(page_qty == undefined) { page_qty = 10; }

        var status = "published";

        var url = base_url.replaceAll("{api_version}", api_version) + `/Article/GetArticles`;
        var article;
        var articles = [];
        //var modified_articles = [];

        // {
        //    "AppKey": "f9ababfe-48a2-40c6-9b4b-d34dacdf353d",
        //    "Page" : 1,
        //    "ResultsPerPage": 2,
        //    "Status": "published"
        // }

        var json = `{"AppKey": "${app_key}","Page":${page},"ResultsPerPage":${page_qty}, "Status":"${status}"}`;

        await axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(function (response) {

            //map to article classes

            for(var i=0; i<response.data.length;i++)
            {
              article = new Article(response.data[i].Id, response.data[i].Title, response.data[i].Body, response.data[i].Blurb, response.data[i].PublishDate);
              articles.push(article);
            }

          })
          .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);

        });

        /*
        await axios.get(url)
        .then(res => {
          const articles = res.data;
          this.state.articles = articles;
          //this.setState({ articles });
        })
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });
        */

        //var modified_articles = [];

        /*
        {this.state.articles.map(function(item, i){
                
            //linkURL = `/article/` + item.id;
            article = new Article(item.Id,item.Title,item.Body, item.PublishDate);
            modified_articles.push(article);
        })}
        */

        return articles;
    }

    async GetArticle(article_id)
    {
 
        //var current_article = null;

        var url = base_url.replaceAll("{api_version}", api_version) + base_url_get_article;
       
        /*
          {
            "AppKey": "f9ababfe-48a2-40c6-9b4b-d34dacdf353d",
            "ArticleId": 1
          } 

        */

        var article = null;
        var author = null;

        var json = `{"AppKey": "${app_key}","ArticleId":${article_id}}`;
        
        await axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(function (response) {

            //map to article classes

            if(response.data.Author != null)
            {
              author = new Author(response.data.Author.Id, response.data.Author.Name, response.data.Author.Blurb, response.data.Author.TwitterHandle);
            }

            article = new Article(response.data.Id, response.data.Title, response.data.Body, response.data.Blurb, response.data.PublishDate);

            article.Author = author;

          })
          .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);

        });

        return article;

        /*
        await axios.get(url)
        .then(res => {

            this.state.current_article = res.data;

            

            //this.state.current_article = current_article;
          //this.setState({ articles });
        })
        .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);
        });

        if(this.state.current_article == null)
        {
            return null;
        }
        else 
        {
            return new Article(this.state.current_article.Id, this.state.current_article.Title, this.state.current_article.Body, this.state.current_article.PublishDate);
        }
        */

    }

    async GetArticleTotalPages(ResultsPerPage)
    {

          if(ResultsPerPage == undefined) { ResultsPerPage = 5; }
  
          var url = base_url.replaceAll("{api_version}", api_version) + base_url_get_articles_total_pages;
          
          var totalPages;
          var status = "PUBLISHED";
  
         //pass json to the api as follows:
         /*
          *  {
          *     "AppKey": "f9ababfe-48a2-40c6-9b4b-d34dacdf353d"
                "Status": "PUBLISHED",
                "ResultsPerPage": 2
            } 
          */
  
          var json = `{"AppKey": "${app_key}","Status": "${status}","ResultsPerPage": ${ResultsPerPage}}`;
          
          await axios.post(url, json, {
              headers: {
                  'Content-Type': 'application/json',
              },
            })
            .then(function (response) {
  
              //get the total pages
  
              totalPages = response;
  
              /*
              for(var i=0; i<response.data.length;i++)
              {
                twitterCommunity = new TwitterCommunity(response.data[i].Id, response.data[i].Name, response.data[i].Description, response.data[i].Enrollment, 
                    response.data[i].Link, response.data[i].ImageURL, response.data[i].Active );
                twitterCommunities.push(twitterCommunity);
  
              }
              */
  
            })
            .catch(error => {
              this.setState({ errorMessage: error.message });
              console.error('There was an error!', error);
  
              if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
                console.log(error.config);
  
          });
  
          return totalPages;

    }

    async GetLatestArticle()
    {
 
        var url = base_url.replaceAll("{api_version}", api_version) + base_url_get_latest_article;
       
        /*
          {
            "AppKey": "f9ababfe-48a2-40c6-9b4b-d34dacdf353d",
            "Status": 'published'
          } 
        */

        var article = null;
        var status = 'published';

        var json = `{"AppKey": "${app_key}","Status":"${status}"}`;
        
        await axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json',
              },
            })
            .then(function (response) {

            //map to article classes

            article = new Article(response.data.Id, response.data.Title, response.data.Body, response.data.PublishDate);

          })
          .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);

        });

        return article;

    }

    async GetTwitterCommunitiesTotalPages(ActiveOnly, ResultsPerPage, SearchFilter, EnrollmentFilter,
      ActiveFilter)
    {

        if(ActiveOnly == undefined) { ActiveOnly = true; }
        if(ResultsPerPage == undefined) { ResultsPerPage = 24; }
        if(SearchFilter == undefined) { SearchFilter = ''; }
        if(EnrollmentFilter == undefined) { EnrollmentFilter = ''; }
        if(ActiveFilter == undefined) { ActiveFilter = ''; }

        var url = base_url.replaceAll("{api_version}", api_version) + base_url_get_twitter_communities_total_pages;
        var totalPages;

       //pass json to the api as follows:
       /*
        *  {
        *     "AppKey": "f9ababfe-48a2-40c6-9b4b-d34dacdf353d"
              "ActiveOnly": true, false or [null]]",
              "ResultsPerPage": [NUMBER],
              "SearchFilter": "",
              "EnrollmentFilter": "OPEN, CLOSED, """,
              "ActiveFilter": "ACTIVE_ONLY, INACTIVE_ONLY, """
          } 
        */

        var json = `{"AppKey": "${app_key}","ActiveOnly": ${ActiveOnly},"ResultsPerPage": ${ResultsPerPage}, "SearchFilter":"${SearchFilter}"}`;

        await axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then(function (response) {

            //get the total pages
            totalPages = response;

          })
          .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);

        });

        return totalPages;

    }

    async GetTwitterCommunities(searchFilter, enrollmentFilter, activeFilter, page)
    {

        if(searchFilter == undefined) { searchFilter = ``; }
        if(enrollmentFilter == undefined) { enrollmentFilter =``; }
        if(activeFilter == undefined) { activeFilter =``; }
        if(page == undefined){ page = 1};

        var url = base_url.replaceAll("{api_version}", api_version) + base_url_get_twitter_communities;
        var twitterCommunity;
        var twitterCommunities = [];
        var modified_twitterCommunities = [];



        /*
          {
            "AppKey": "f9ababfe-48a2-40c6-9b4b-d34dacdf353d",
            "SearchFilter": "",
            "EnrollmentFilter" : "OPEN",
            "ActiveFilter": ""
          } 
        */

        var json = `{"AppKey": "${app_key}","SearchFilter":"${searchFilter}","EnrollmentFilter":"${enrollmentFilter}","ActiveFilter":"${activeFilter}","ResultsPerPage":24, "Page":${page}}`;

        await axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json',
            },
          })
          .then(function (response) {

            //map to twitter community classes

            for(var i=0; i<response.data.length;i++)
            {
              twitterCommunity = new TwitterCommunity(response.data[i].Id, response.data[i].Name, response.data[i].Description, response.data[i].Enrollment, 
                  response.data[i].Link, response.data[i].ImageURL, response.data[i].Active );
              twitterCommunities.push(twitterCommunity);

            }

          })
          .catch(error => {
            this.setState({ errorMessage: error.message });
            console.error('There was an error!', error);

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);

        });

        return twitterCommunities;
    }

    async SubmitTwitterCommunity(name, link)
    {
      if(name == undefined) { name = ``; }
      if(link == undefined) { link =``; }

      var url = base_url.replaceAll("{api_version}", api_version) + base_url_submit_twitter_community;
      var twitterCommunity;
      var twitterCommunities = [];
      var modified_twitterCommunities = [];

      /*
        {
          "AppKey": "f9ababfe-48a2-40c6-9b4b-d34dacdf353d",
          "name": "",
          "link" : "",
        } 
      */

      var json = `{"AppKey": "${app_key}","name":"${name}","link":"${link}"}`;

      await axios.post(url, json, {
          headers: {
              'Content-Type': 'application/json',
          },
        })
        .then(function (response) {

          //map to twitter community classes

          console.log(response);

          /*
          for(var i=0; i<response.data.length;i++)
          {
            twitterCommunity = new TwitterCommunity(response.data[i].Id, response.data[i].Name, response.data[i].Description, response.data[i].Enrollment, 
                response.data[i].Link, response.data[i].ImageURL, response.data[i].Active );
            twitterCommunities.push(twitterCommunity);

          }
          */

        })
        .catch(error => {
          this.setState({ errorMessage: error.message });
          console.error('There was an error!', error);

          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);

      });

      return twitterCommunities;
    }

    /*
    async GetTwitterCommunities_old(enrollmentStatus)
    {
      var twitterCommunities = [];
      var twitterCommunity = null;
      var name = "";
      var desc = "";
      var enrollment = "";
      var link = "";
      var img = "";
      
      name = "Xbox";
      desc = "Join the Xbox community to discuss the latest news on Xbox games, consoles, and more. Not an official Xbox or Microsoft community.";
      enrollment = "Open";
      link = "https://twitter.com/i/communities/1455305732912418820";
      img = "https://pbs.twimg.com/list_banner_img/1455305571427520515/0xD8ECfA?format=jpg&name=small";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);    
      twitterCommunities.push(twitterCommunity);

      name = "R&amp;B Twitter";
      desc = "Your place to connect with R&amp;B lovers around the world and engage in discussions about the genre.";
      enrollment = "Open";
      link = "https://twitter.com/i/communities/1473375845628710916";
      img = "https://pbs.twimg.com/media/FECTM-lVIAMfwd1?format=jpg&name=small";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);
      twitterCommunities.push(twitterCommunity);

      name = "Wordle";
      desc = "To be able to share Wordle scores without angering the broader twitter masses.";
      enrollment = "Open";
      link = "https://twitter.com/i/communities/1481993465374380041";
      img = "https://pbs.twimg.com/community_banner_img/1481993993147850755/76cuTvvI?format=jpg&name=360x360";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);
      twitterCommunities.push(twitterCommunity);

      name = "Radio Roulette Community";
      desc = "Discussing all things NBA, Wob, World News, TV, idk maybe even WWII facts. A 24/7 home base for Radio Roulette callers who dont take the internet too seriously.";
      enrollment = "Open";
      link = "https://twitter.com/i/communities/1471561824491368449";
      img = "https://pbs.twimg.com/community_banner_img/1471562902955905025/ORG57xdI?format=jpg&name=small";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);
      twitterCommunities.push(twitterCommunity);

      name = "Astro Twitter";
      desc = "A place for those who want to learn, teach and inspire others using the stars! Open to all!";
      enrollment = "Open";
      link = "https://twitter.com/i/communities/1433240493408722945/";
      img = "https://pbs.twimg.com/list_banner_img/1433241211351932936/LMdXKhec?format=jpg&name=small";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);
      twitterCommunities.push(twitterCommunity);

      name = "Twitter Spaces";
      desc = "Focused on continual improvement of best practices of Twitter Spaces for hosts, speakers, and listeners #SpacesCommunity user group";
      enrollment = "Open";
      link = "https://twitter.com/i/communities/1443304499981045775";
      img = "https://pbs.twimg.com/community_banner_img/1481023702007492614/Yz0nhLE5?format=jpg&name=small";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);
      twitterCommunities.push(twitterCommunity);

      name = "Creators";
      desc = "Helping creators best monetize their brand";
      enrollment = "Closed";
      link = "https://twitter.com/i/communities/1452802766355709952";
      img = "https://pbs.twimg.com/list_banner_img/1452802603633369090/whkVkfxG?format=png&name=small";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);
      twitterCommunities.push(twitterCommunity);

      name = "Minimalist Entrepreneurs";
      desc = "To discuss how to start and scale sustainable businesses using software";
      enrollment = "Open";
      link = "https://twitter.com/i/communities/1452793387510272002";
      img = "https://pbs.twimg.com/list_banner_img/1452793152499171329/ye-AecXZ?format=jpg&name=small";

      twitterCommunity = new TwitterCommunity(name, desc, enrollment, link, img);
      twitterCommunities.push(twitterCommunity);

      return twitterCommunities;
    }
    */

}